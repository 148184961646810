import { getWindow } from '@exp/exp-utils/helper/browser';
import { getObjFromTDataList } from '@tcc/shared/src/helpers/tdata';

const cmdLogPerf = (timingObject, usrin) => {
  getWindow()._expDataLayer.push({
    schema: 'add_perf',
    version: 'v1',
    data: {
      timing_object: timingObject,
      custom_properties: getObjFromTDataList(usrin)
    }});
};

export default cmdLogPerf;
