import { getDocument } from '@exp/exp-utils/helper/browser';
import LoadHelper from '@exp/exp-utils/helper/load';

const _pageLoadHelper = new LoadHelper();

const withCookieCache = (onCacheFn) => {
  try {
    let cachedCookie = getDocument().cookie;
    let cookieAccessor;
    Object.defineProperty(document, 'cookie', {
      get: () => cachedCookie,
      set: (v) => {
        cookieAccessor = cookieAccessor || Object.getOwnPropertyDescriptor(Document.prototype, 'cookie');
        cookieAccessor.set.call(document, v);
        cachedCookie = cookieAccessor.get.call(document);
      },
      configurable: true,
      enumerable: true
    });
    onCacheFn();
  } finally {
    delete getDocument().cookie;
  }
};

export default _pageLoadHelper;
export { withCookieCache };
