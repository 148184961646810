
import SchemaHandler from '@exp/exp-utils/schema/schemaHandler';

import PropertySet from '@exp/exp-utils/helper/property';
import { sendEvent } from '@tcc/shared/src/traffic/eventSend';
import { getHostname, getReferrer, getUserAgent, getPageName } from '../../helpers/tcclUtility';
import { rand } from '@exp/exp-utils/helper/math';
import VisitHelperTccl from '../../helpers/visitHelperTccl';
import TData from '@tcc/shared/src/helpers/tdata';
import { processPageLevelProperties } from '@tcc/shared/src/configManager';
import config from '@exp/exp-utils/helper/config';

const vci = rand();

class BaseEventHandler extends SchemaHandler {
  process() {
    super.process({
      ALL: () => { this._handleEventSvc(); }
    });
  }

  _handleEventSvc() {
    sendEvent(this.pageEvent.getProperties(), '/t/1/tl/event', 'GET');
  }

  preProcess() {
    this.tData = new TData();
    this.tData.merge(this.data.ALL.custom_properties);

    // this.pageEvent = createEventProperties('pageevent', this.tData);
    // addEventProperties(this.pageEvent, this.data.ALL.type);
    this.pageEvent = new PropertySet();

    this.pageEvent.set('usrin', this.tData.stringify());

    // adds session data and page based data
    const visitHelper = new VisitHelperTccl();
    var sids = visitHelper.getVisitInfo(),
      vtg  = sids.visitorGuid,
      vg   = sids.visitGuid;

    // attach the generic data used on all events.
    this.pageEvent.set('cts', new Date().getTime());
    this.pageEvent.set('dh', getHostname());
    this.pageEvent.set('dr', getReferrer(1000));
    this.pageEvent.set('ua', getUserAgent());
    this.pageEvent.set('vci', vci);

    this.pageEvent.set('cv', config.get('tccl.buildVersion'));
    this.pageEvent.set('z', rand());
    this.pageEvent.set('vg', vg);
    this.pageEvent.set('vtg', vtg);
    this.pageEvent.set('dp', getPageName());

    // set app name & page level properties
    const pageProps = processPageLevelProperties();
    this.pageEvent.set('ap', pageProps.ap ? pageProps.ap : 'not_set');
    this.pageEvent.set('trfd', JSON.stringify(pageProps));

    // Merge datalayer extras, which includes an identifier to
    // correlate the hit between downstream systems (hit_id)
    this.pageEvent.merge(this.extras);
  }
}

export default BaseEventHandler;
